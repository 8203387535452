export class Message<T = any> {
  eventName: string
  payload: T
  subscriptionGuid: Guid // we want to make it easy to unsubscribe
}

type IHash = Record<string, IdentifiableFunction[]>
export class IdentifiableFunction {
  public mainFunction: Function
  public identifier: Guid
}

/**
 * @todo
 * A lot of times this is used to generate a unique identifier
 * for something like a DOM element, but a Guid is extreme
 * overkill for this, and we could use nanoid.
 */
export class Guid {
  content: string
  // Is there  way to make this private??
  constructor(content?: string) {
    this.content = content ?? 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

  toString() {
    return this.content
  }
}

/*
 * Used by the ViewModelSubscribeToMessageBus class.
 */
export class BusSubscription {
  constructor(public eventName: string, public guid: Guid) {}
}

/**
 * @todo Please please document what this is for.
 */
export class Bus {
  private channels: IHash

  constructor() {
    this.channels = {}
  }

  publish(msg: Message<any>): boolean {
    if (this.channels.hasOwnProperty(msg.eventName)) {
      const channel = this.channels[msg.eventName]
      for (const sub of channel) {
        msg.subscriptionGuid = sub.identifier
        sub.mainFunction(msg)
      }
      return true
    }
    return false
  };

  subscribe(eventName: string, subscription: Function): Guid {
    if (!this.channels.hasOwnProperty(eventName)) {
      this.channels[eventName] = new Array<IdentifiableFunction>()
    }

    const subAndId = new IdentifiableFunction()
    subAndId.mainFunction = subscription
    subAndId.identifier = new Guid()
    this.channels[eventName].push(subAndId)
    return subAndId.identifier
  };

  unsubscribe(eventName: string): void
  unsubscribe(eventName: string, one: Guid): void// This is how you do parameter overriding;
  unsubscribe(eventName: string, one?: Guid): void {
    if (one && this.channels[eventName] != null) {
      const channel = this.channels[eventName]
      let found: IdentifiableFunction | null = null
      for (const sub of channel) {
        if (sub.identifier.content === one.content) {
          found = sub
          break
        }
      }
      if (found != null) {
        const index = channel.indexOf(found, 0)
        if (index > -1) {
          channel.splice(index, 1)
        }
      }
    } else if (this.channels[eventName] != null) {
      delete this.channels[eventName] // removes the property
    }
  };
}
