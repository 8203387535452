import { default as _91_46_46_46slug_936LSQnaz0GVMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/[...slug].vue?macro=true";
import { default as indexJ8dsbl1ZVZMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/billing/index.vue?macro=true";
import { default as invoiceXYLdk8f6GxMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/billing/invoice.vue?macro=true";
import { default as payment_45info0h6lyfusqFMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/billing/payment-info.vue?macro=true";
import { default as change_45passwordglrzw5RVsZMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/change-password.vue?macro=true";
import { default as indexNcW5OfZ7IsMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/index.vue?macro=true";
import { default as planspTvKY8eqLaMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/plans.vue?macro=true";
import { default as profileMB4ptCTx9VMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/account/profile.vue?macro=true";
import { default as indexfR0hbwuGPHMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/affiliate/index.vue?macro=true";
import { default as overrides9tj9z591rvMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/affiliate/overrides.vue?macro=true";
import { default as cancel_45account_45doneaJ9PbOH90FMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/cancel-account-done.vue?macro=true";
import { default as cancel_45account_45loadingzwMO56lxB0Meta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/cancel-account-loading.vue?macro=true";
import { default as forgot_45passwordvO2H3bCoTLMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/forgot-password.vue?macro=true";
import { default as indexc6vVRHYzzbMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/groups/index.vue?macro=true";
import { default as summaryRi36u3YXTrMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/groups/summary.vue?macro=true";
import { default as indexXEC7aboZM9Meta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/home/index.vue?macro=true";
import { default as indexkmSzVQER81Meta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/index.vue?macro=true";
import { default as confirm6Lw0l2gVeqMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/join/confirm.vue?macro=true";
import { default as indexBKJn7xcU66Meta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/join/index.vue?macro=true";
import { default as indexf7X2dxwzg2Meta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/links/index.vue?macro=true";
import { default as summaryH50uivzzpEMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/links/summary.vue?macro=true";
import { default as loginDSSuoVQgXfMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/login.vue?macro=true";
import { default as new_45pageJ313CEtYVVMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/new-page.vue?macro=true";
import { default as reports3qhMZu4jRbMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/reports.vue?macro=true";
import { default as reset_45passwordcEUpK3kr8TMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/reset-password.vue?macro=true";
import { default as setuptqtP99SBGGMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/setup.vue?macro=true";
import { default as termsqSIeOWKseAMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/terms.vue?macro=true";
import { default as indexthVSXqPXvlMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/tools/index.vue?macro=true";
import { default as snippetcreatorvaxhxV8NoGMeta } from "/core.git/sln/dashboard/dashboard-fe/src/pages/tools/snippetcreator.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_936LSQnaz0GVMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/[...slug].vue")
  },
  {
    name: "account-billing",
    path: "/account/billing",
    meta: indexJ8dsbl1ZVZMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/billing/index.vue")
  },
  {
    name: "account-billing-invoice",
    path: "/account/billing/invoice",
    meta: invoiceXYLdk8f6GxMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/billing/invoice.vue")
  },
  {
    name: "account-billing-payment-info",
    path: "/account/billing/payment-info",
    meta: payment_45info0h6lyfusqFMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/billing/payment-info.vue")
  },
  {
    name: "account-change-password",
    path: "/account/change-password",
    meta: change_45passwordglrzw5RVsZMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/change-password.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexNcW5OfZ7IsMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/index.vue")
  },
  {
    name: "account-plans",
    path: "/account/plans",
    meta: planspTvKY8eqLaMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/plans.vue")
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileMB4ptCTx9VMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/account/profile.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: indexfR0hbwuGPHMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/affiliate/index.vue")
  },
  {
    name: "affiliate-overrides",
    path: "/affiliate/overrides",
    meta: overrides9tj9z591rvMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/affiliate/overrides.vue")
  },
  {
    name: "cancel-account-done",
    path: "/cancel-account-done",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/cancel-account-done.vue")
  },
  {
    name: "cancel-account-loading",
    path: "/cancel-account-loading",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/cancel-account-loading.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/forgot-password.vue")
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexc6vVRHYzzbMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/groups/index.vue")
  },
  {
    name: "groups-summary",
    path: "/groups/summary",
    meta: summaryRi36u3YXTrMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/groups/summary.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: indexXEC7aboZM9Meta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/home/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/index.vue")
  },
  {
    name: "join-confirm",
    path: "/join/confirm",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/join/confirm.vue")
  },
  {
    name: "join",
    path: "/join",
    meta: indexBKJn7xcU66Meta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/join/index.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexf7X2dxwzg2Meta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/links/index.vue")
  },
  {
    name: "links-summary",
    path: "/links/summary",
    meta: summaryH50uivzzpEMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/links/summary.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginDSSuoVQgXfMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/login.vue")
  },
  {
    name: "new-page",
    path: "/new-page",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/new-page.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: reports3qhMZu4jRbMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/reports.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/reset-password.vue")
  },
  {
    name: "setup",
    path: "/setup",
    meta: setuptqtP99SBGGMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/setup.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/terms.vue")
  },
  {
    name: "tools",
    path: "/tools",
    meta: indexthVSXqPXvlMeta || {},
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/tools/index.vue")
  },
  {
    name: "tools-snippetcreator",
    path: "/tools/snippetcreator",
    component: () => import("/core.git/sln/dashboard/dashboard-fe/src/pages/tools/snippetcreator.vue")
  }
]