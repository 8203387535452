import Intercom, { update, trackEvent } from '@intercom/messenger-js-sdk'
import env from '/env.js'
import { Session } from '~/state'
import type { UserArgs } from '@intercom/messenger-js-sdk/dist/types'
import { watchEffect } from 'vue'
import { DateTime } from 'luxon'

export type IIntercomProxy = IntercomProxy

const setSettings = (args: UserArgs) => {
  update({ ...args })
}

export const setup = () => {
  Intercom({
    app_id: env.app_id_intercom
  })

  /** Send new settings to Intercom whenever session vars change */
  watchEffect(() => {
    const createdAt = DateTime.fromJSDate(new Date(Session.Profile.Setup.CompletedUtc)).toUnixInteger()
    const trialExpiration = DateTime.fromJSDate(new Date(Session.ScheduledTrialEndDate))
    const email = Session.Profile.Details.Email
    if (email) {
      setSettings({
        name: `${Session.Profile.Details.FirstName} ${Session.Profile.Details.LastName}`,
        email: Session.Profile.Details.Email,
        user_id: Session.UserName.toLowerCase(),
        signed_up_at: createdAt, // Signup date as a Unix timestamp
        created_at: createdAt, // auto set by intercom? lets just leave for now even though duplicates
        parent_account: Session.Profile.ParentUsername ?? '',
        update_last_request_at: true, // this just updates the time last seen
        plan_type: Session.Plan.PlanType,
        plan_name: Session.Plan.Id,
        trial_expiration: trialExpiration.toUnixInteger(), // moment(intercomTrialExpirationDate).unix(),
        trial_exp_minus_ten_days: trialExpiration.minus({ days: 10 }).toUnixInteger(),
        user_hash: Session.IntercomUsernameHash, // HMAC using SHA-256
        is_closed: Session.Profile.Setup.AccountClosed,
        requires_cc: Session.Plan.RequiresPaymentMethodOnFile,
        is_trial_paywalled: Session.Plan.RequiresPaymentMethodOnFileDuringTrial,
        is_cc_invalid: Session.Profile.Setup.CardIsInvalid,
        is_email_invalid: Session.Profile.Setup.EmailIsInvalid,
        isImpersonatedAdmin: Session.IsImpersonatedAdminSession,
        is_suspended: Session.Profile.Setup.AccountSuspended
      })
    }
  })
}

export let intercomProxy: IntercomProxy

export class IntercomProxy implements IIntercomProxy {
  static readonly Update: string = 'update'
  static readonly TrackEvent: string = 'trackEvent'

  isAdminImpersonatingUser(): boolean {
    return Session.IsImpersonatedAdminSession ?? false
  }

  // any reason to not allow sending things to intercom
  shouldCallIntercom(): boolean {
    return !this.isAdminImpersonatingUser()
  }

  // update the user attribute value
  update(aName: string, aValue: any): void {
    update({ [aName]: aValue })
  }

  updateObject(obj: any): void {
    update(obj)
  }

  // we use for page views and other actions/ feature access
  trackEvent(eventName: any, eventMetadata?: any): void {
    trackEvent(eventName, eventMetadata)
  }

  // Eg shutdown, boot
  action(actionName: any): void {
    Intercom(actionName)
  }
}

// eslint-disable-next-line prefer-const
intercomProxy = new IntercomProxy()
