import { type IConfig } from 'swrv'
import SWRVCache from 'swrv/dist/cache'

const config: IConfig<any> = {
  errorRetryCount: 3,
  revalidateOnFocus: false,
  /** Cache stays for 10 minutes (given same inputs) */
  ttl: 1000 * 60 * 10,
  cache: new SWRVCache()
}

export default config
