declare let Rollbar: any
declare let runRollbar: any

export const RollbarProxy = {
  rollbarExists(): boolean {
    return window.hasOwnProperty('Rollbar') && window.hasOwnProperty('runRollbar') && runRollbar && Rollbar !== undefined
  },

  validateRollbar(logFunction: Function): void {
    try {
      if (!RollbarProxy.rollbarExists()) { throw 'Rollbar not configured to run.' }
      if (logFunction === undefined) { throw 'Log function undefined.' }
      if (logFunction === null) { throw 'Log function null.' }

      logFunction(Rollbar)
    } catch (error) {
      logFunction(console)
    }
  },

  error(msg: string, params?: object): void {
    this.validateRollbar((Rollbar: any) => {
      return Rollbar.error(msg, params)
    })
  },

  warning(msg: string, params?: object): void {
    this.validateRollbar((Rollbar: any) => {
      return Rollbar.warning(msg, params)
    })
  },

  critical(msg: string, params?: object): void {
    this.validateRollbar((Rollbar: any) => {
      if ('critical' in Rollbar) {
        return Rollbar.critical(msg, params)
      }
      return Rollbar.error(msg, params)
    })
  },

  info(msg: string, params?: object): void {
    this.validateRollbar((Rollbar: any) => {
      return Rollbar.info(msg, params)
    })
  },

  debug(msg: string, params?: object): void {
    this.validateRollbar((Rollbar: any) => {
      return Rollbar.debug(msg, params)
    })
  }
}
