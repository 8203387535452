<script lang="ts">
import AppRoot, { KeyDownAtom } from './app-root.vue'
import Highcharts from 'highcharts'
import HighchartsMapModule from 'highcharts/modules/map'
import { abbrNum } from './scripts/pretty-printing'

HighchartsMapModule(Highcharts)

/**
 * Add global template helper
 * @see https://www.highcharts.com/docs/chart-concepts/templating
 */
;(Highcharts as any).Templating.helpers.abbrNum = (number: number) => abbrNum(number, 1)

export { KeyDownAtom }
</script>

<script setup lang="ts">
import { NuxtLayout, NuxtPage, NuxtLoadingIndicator } from '#components'
</script>

<template>
  <AppRoot>
    <!-- <SeoKit /> -->
    <NuxtLoadingIndicator />
    <NuxtLayout ref="layout">
      <Suspense>
        <NuxtPage />
      </Suspense>
    </NuxtLayout>
  </AppRoot>
</template>
