import {
  string,
  object,
  picklist,
  trim,
  pipe,
  minLength,
  url,
  optional,
  array,
  custom,
  literal,
  boolean,
  tuple,
  union,
  type InferOutput
} from 'valibot'

export interface Override {
  name: string
  cssForIcon: string
  displayName: string
}

export interface Device extends Override {}
export interface Browser extends Override {}

export interface OSVersion {
  major: string
  minor?: string
  patch?: string
  patchMinor?: string
  versionName: string
  displayName: string
}

export interface OperatingSystem extends Override {
  versions?: OSVersion[]
}

export interface SystemPreset extends Override {}

export interface Overrides {
  deviceList: Device[]
  browserOverrideOptions: Browser[]
  osList: OperatingSystem[]
  /** Essentially Region? */
  systemPresetsList: SystemPreset[]
  userPresetsList: Override[] // Assuming this could be extended later?
}

/** Normalizes these keys for the front end */
export interface OverrideOptions {
  browserOptions: Overrides['browserOverrideOptions']
  deviceOptions: Overrides['deviceList']
  osOptions: Overrides['osList']
  systemPresetOptions: Overrides['systemPresetsList']
  userPresetOptions: Overrides['userPresetsList']
}

export interface LanguageOption {
  abreviationTwoChar: string
  englishName: string
}

export interface LanguageOptions {
  isError: boolean
  languages: LanguageOption[]
}

export const UrlSchema = pipe(
  string(),
  trim(),
  minLength(1, 'Url required'),
  url('Must be a valid Url')
)

const OptionSchema = object({
  operator: string(),
  type: string(),
  value: string()
})

/** User-defined override rules */
export const RuleSchema = object({
  /** used on front end but not serialized */
  displayName: optional(string()),

  /** From the server model */
  type: string(),
  operator: string(),
  value: string(),
  hiddenValue: optional(string()),
  dateBeforeOrAfter: optional(picklist(['before', 'after'])),
  options: optional(array(OptionSchema))
})

export type Rule = InferOutput<typeof RuleSchema>

export const DestinationSchema = object({
  percentage: pipe(
    string(),
    custom(s => {
      const num = Number(s)
      return !isNaN(num) && num >= 0 && num <= 100
    })
  ),
  url: UrlSchema
})

export type Destination = InferOutput<typeof DestinationSchema>

export const SingleDestinationOverrideSchema = object({
  type: literal('SingleDestination'),
  url: UrlSchema,
  rules: array(RuleSchema)
})

export type SingleDestinationOverride = InferOutput<typeof SingleDestinationOverrideSchema>

export const WeightedDestinationsOverrideSchema = object({
  type: literal('WeightedDestinations'),
  url: UrlSchema,
  rules: array(RuleSchema),
  destinations: array(DestinationSchema)
})

export type WeightedDestinationsOverride = InferOutput<typeof WeightedDestinationsOverrideSchema>

export const ChoicePageDestinationSchema = object({
  destinationUrl: UrlSchema,
  logo: string(),
  replacementButton: string(),
  urlText: string(),
  isSuggestion: boolean()
})

export type ChoicePageDestination = InferOutput<typeof ChoicePageDestinationSchema>

const ThemeSchema = object({
  backgroundClass: picklist(['light', 'dark'])
})

export const ChoicePageOverrideSchema = object({
  /** "Page top logo" in UI */
  logoUrl: UrlSchema,
  /** "Product image" in UI */
  highlightImageUrl: UrlSchema,
  /** Title on page */
  titleText: string(),
  /** Call to action */
  flavorText: string(),
  destinationUrls: array(ChoicePageDestinationSchema),
  themes: tuple([ThemeSchema]),
  hideAffiliateDisclosure: boolean()
})

export type ChoicePageOverride = InferOutput<typeof ChoicePageOverrideSchema>

export const CustomerSelectedDestinationsOverrideSchema = object({
  type: literal('CustomerSelectedDestinations'),
  url: UrlSchema,
  rules: array(RuleSchema),
  customerSelectedOverride: ChoicePageOverrideSchema
})

export type CustomerSelectedDestinationsOverride =
InferOutput<typeof CustomerSelectedDestinationsOverrideSchema>

export const DestinationOverrideSchema = union([
  SingleDestinationOverrideSchema,
  WeightedDestinationsOverrideSchema,
  CustomerSelectedDestinationsOverrideSchema
])

export type DestinationOverride = InferOutput<typeof DestinationOverrideSchema>

export interface OverrideRules {
  /** What on earth is this key name */
  holder: DestinationOverride[]
}
