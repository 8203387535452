import { defineNuxtPlugin } from '#app'

/** For testing */
export default defineNuxtPlugin({
  name: 'css-register',
  hooks: {
    'app:beforeMount'() {
      // const styles = document.querySelectorAll('style')
      // console.log('Initial styles:', styles.length)
    },
    'link:prefetch'(to) {
      // console.log('Prefetching:', to)
      // const styles = document.querySelectorAll('style')
      // console.log('Styles from (or before?) prefetching:', styles.length)
    },
    'page:loading:start'() {
      // const styles = document.querySelectorAll('style')
      // console.log('Number of styles:', styles.length)
    }
  }
})
