import env from '/env.js'

/**
 * Appends common settings for the URL endpoints
 */
export function resolveClientUrl(urlString: string, data?: string | Record<string, any>) {
  const url = new URL(urlString, env.API_URL)
  /** Combine data search params with search params in the URL */
  if (typeof data === 'string') {
    const dataEntries = new URLSearchParams(data)
    dataEntries.forEach((value, key) => url.searchParams.set(key, value))
  } else if (typeof data === 'object') {
    for (const [key, value] of Object.entries(data)) {
      url.searchParams.set(key, value)
    }
  }
  /** @see https://docs.servicestack.net/customize-json-responses#custom-json-settings */
  const configArr = url.searchParams.get('jsconfig')?.split(',') ?? []
  configArr.push('dh:iso8601')
  /** Set 'json' as the default format */
  const format = url.searchParams.get('format') ?? 'json'
  url.searchParams.set('jsconfig', configArr.join(','))
  url.searchParams.set('format', format)
  return `${url.origin}${url.pathname}${url.search}`
}
