import {
  string,
  regex,
  maxLength,
  check,
  pipe
} from 'valibot'

// presets
interface Preset {
  name: string
  value: string
}

export const presetCustom = {
  name: 'Type a name...',
  value: 'custom'
} as const satisfies Preset

export const presetGroupName = {
  name: '{Group name}',
  value: '{group_name}'
} as const satisfies Preset

export const presetUsername = {
  name: '{Your username}',
  value: '{username}'
} as const satisfies Preset

export const presetShorturlCode = {
  name: '{Short URL / vanity code}',
  value: '{shorturl_code}'
} as const satisfies Preset

export const presetReferrerUrl = {
  name: '{Click referrer URL}',
  value: '{referrer_url}'
} satisfies Preset

export const tagTypes = ['campaign', 'source', 'medium', 'content', 'term'] as const
export type TagType = typeof tagTypes[number]

const presets = [
  presetCustom,
  presetGroupName,
  presetUsername,
  presetShorturlCode,
  presetReferrerUrl
]

const possiblePresetDetected = function(value: string) {
  const startIndex = value.indexOf('{')
  const endIndex = value.indexOf('}')

  return (startIndex - value.lastIndexOf('{') === 0)
    && (endIndex - value.lastIndexOf('}') === 0)
    && (startIndex < endIndex)
}

export const TagSchema =
  pipe(
    string(),
    // validTag
    regex(/[^ ;/?:@&=+$,[\]()'*#!]+/, "Sorry, no empty spaces or ;/!?#:@,&=+$'*[]()"),
    // tagLength
    maxLength(46, 'Sorry, field must be less than 46 characters.'),
    // isPreset
    check((tag) => {
      const startIndex = tag.indexOf('{')
      let endIndex = tag.indexOf('}')
      let isLegal = (startIndex < 0 && endIndex < 0)

      if (possiblePresetDetected(tag)) {
        isLegal = false

        endIndex += 1

        const possiblePreset = tag.substring(startIndex, endIndex)

        presets.forEach(preset => {
          if (possiblePreset === preset.value) {
            isLegal = true
          }
        })
      }

      return isLegal
    },
    'Sorry, currently only one supported preset.'
    )
  )
