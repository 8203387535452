import { Bus } from './scripts/Bus/bus.ts'

export const bus = new Bus()
/** legacy support */
window.Bus = bus

export * from './csharp/Session'
export { FeatureFromSession } from './csharp/FeatureFromSession'
export { Feature } from './csharp/Feature'
export { default as Settings } from './csharp/Settings'
export { SystemRoles } from './csharp/SystemRoles'
export { ProfileFromSession } from './csharp/ProfileFromSession'
