import { defineNuxtRouteMiddleware } from '#imports'
import { Session } from '~/state'
import { useClientPromise } from '~/utils'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const isLoginPage = to.name === 'login'
  if (
    (to.meta.layout === 'legacy' || isLoginPage)
    && !Session.IsAuthenticated
  ) {
    const [err, res] = await useClientPromise(
      '/v3/account/session',
      {
        query: {
          /**
           * Use PascalCase for Session to make Razor transition easier
           * For documentation on jsconfig, @see https://docs.servicestack.net/customize-json-responses#custom-json-settings
           */
          jsconfig: 'epcn'
        }
      }
    )
    if (err) {
      /** Don't double navigate */
      if (!to.query.continue && !isLoginPage) {
        return navigateTo(
            `/login?continue=${encodeURIComponent(to.fullPath)}`,
            {
              replace: true
            }
        )
      }
    } else {
      const data = res.SessionData
      Session.reset(data)
      if (Session.IsAuthenticated && from.query.continue) {
        return navigateTo(from.query.continue as string, { replace: true })
      }
    }
  } else {
    /** Refresh session data regardless */
    console.log('updating session... ')
    useClientPromise('/v3/account/session').then(([err, res]) => {
      if (!err) {
        Session.set(res.SessionData)
      }
    })
  }
})
