import { reactive } from 'vue'
import mergeWith from 'lodash/mergeWith'
import merge from 'lodash/merge'

import { type SystemRoles } from './SystemRoles'
import { type Feature, type IFeature } from './Feature'
import env from '/env.js'

/** This is a kind of app state object */
export interface DefaultSessionObject {
  UnImpersonatedAdminEmail: string
  UnImpersonatedAdminUsername: string
  UnImpersonatedChildEmail: string
  UnImpersonatedChildUsername: string
  IsTrialActive: boolean
  IsLimitedAccessTrial: boolean
  IsImpersonatedAdminSession: boolean
  IsImpersonatedChildSession: boolean

  Metrics: Record<string, any>
  ScheduledTrialEndDate: any
  IntercomUsernameHash: string
  TriggerTracking: boolean

  Profile: {
    Id: string
    ParentUsername: string

    /** These are placed here from root after receiving the server session object */
    UserName: string
    Email: string

    HasValidCreditCardOnFile: boolean
    HasManualPaymentMethod: boolean
    IsChildAccount: boolean

    Billing: {
      HasCreditCardOnFile: boolean
      NetPaymentTerms: number
      PaymentMethod: number
      Stripe: {
        CardAddedUtc: string
        CustomerId: string
        DefaultCardId: string
        DefaultCardLast4: string
        ExpirationDate: string
        [k: string]: any
      }
      StripeChanges: Array<Record<string, any>>
      [k: string]: any
    }

    Details: {
      FirstName: string
      LastName: string
      Email: string
      IsTestAccount: boolean
      OtherKeys: string[]
      Address2: string
      City: string
      Company: string
      Country: string
      State: string
      PhoneNumber: string
      PostalCode: string
      [k: string]: any
    }
    Setup: {
      AccountSuspended: boolean
      AccountClosed: boolean
      CompletedUtc: string
      CardIsInvalid: boolean
      EmailIsInvalid: boolean
      CreditCardPending: boolean
    }
  }

  Roles: SystemRoles[]

  Plan: {
    Id: string
    PlanType: string
    DisplayName: string
    Amount: number
    PricePerBucketInCents: number
    BucketSizeInClicks: number
    RequiresPaymentMethodOnFile: boolean
    RequiresPaymentMethodOnFileDuringTrial: boolean
    Features: Map<string, IFeature>
  }
}

export interface ServerSessionObject extends Omit<DefaultSessionObject, 'Plan'> {
  UserName?: string
  Email?: string
  Plan: Omit<DefaultSessionObject['Plan'], 'Features'> & {
    Features: IFeature[]
  }
}

export interface SessionObject extends DefaultSessionObject {
  IsAuthenticated: boolean
  Billing: DefaultSessionObject['Profile']['Billing'] & {
    HasPaymentMethodOnFile: boolean
  }
  Profile: DefaultSessionObject['Profile'] & {
    FullName: string
  }
  UserName: string
  Email: string
  IsChildAccount: boolean
  reset: (data?: ServerSessionObject) => void
  set: (data: ServerSessionObject) => void
  HasRole: (role: SystemRoles | `${SystemRoles}`) => boolean
  HasFeature: (feature: Feature | `${Feature}`) => boolean
  CanAddUsers: () => boolean
}

const getDefaultSessionSettings = () => ({
  UnImpersonatedAdminEmail: '',
  UnImpersonatedAdminUsername: '',
  UnImpersonatedChildEmail: '',
  UnImpersonatedChildUsername: '',
  IsTrialActive: false,
  IsImpersonatedAdminSession: false,
  IsImpersonatedChildSession: false,
  Metrics: {},
  ScheduledTrialEndDate: undefined,
  IntercomUsernameHash: '',
  TriggerTracking: false,
  IsLimitedAccessTrial: false,
  Roles: [],

  Profile: {
    Id: '',
    ParentUsername: '',
    UserName: '',
    Email: '',
    HasValidCreditCardOnFile: false,
    HasManualPaymentMethod: false,
    IsChildAccount: false,

    Setup: {
      AccountSuspended: false,
      AccountClosed: false,
      CompletedUtc: '',
      CardIsInvalid: false,
      CreditCardPending: false,
      EmailIsInvalid: false
    },
    Details: {
      FirstName: '',
      LastName: '',
      Email: '',
      Address2: '',
      City: '',
      Company: '',
      Country: '',
      PhoneNumber: '',
      State: '',
      PostalCode: '',
      IsTestAccount: false,
      OtherKeys: []
    },
    Billing: {
      HasCreditCardOnFile: false,
      NetPaymentTerms: 0,
      PaymentMethod: 0,
      Stripe: {
        CardAddedUtc: '',
        CustomerId: '',
        DefaultCardId: '',
        DefaultCardLast4: '',
        ExpirationDate: ''
      },
      StripeChanges: []
    }
  },

  Plan: {
    Id: '',
    Features: new Map(),
    PlanType: '',
    DisplayName: '',
    Amount: 0,
    PricePerBucketInCents: 0,
    BucketSizeInClicks: 0,
    RequiresPaymentMethodOnFile: false,
    RequiresPaymentMethodOnFileDuringTrial: false
  }
} satisfies DefaultSessionObject)

const Session: SessionObject = reactive(getDefaultSessionSettings()) as any

Object.defineProperties(Session.Profile, {
  FullName: {
    get() {
      return `${Session.Profile.Details.FirstName} ${Session.Profile.Details.LastName}`
    }
  }
})

Object.defineProperties(Session, {
  CanAddUsers: {
    get() {
      return () => Session.HasFeature('Team Accounts')
        && !Session.Profile.ParentUsername
        && !Session.Profile.Setup.AccountClosed
        && !Session.Profile.Setup.AccountSuspended
    }
  },
  Billing: {
    get() {
      return Session.Profile.Billing
    }
  },
  UserName: {
    get() {
      return Session.Profile.UserName
    }
  },
  Email: {
    get() {
      return Session.Profile.Email
    }
  },
  IsChildAccount: {
    get() {
      return Session.Profile.IsChildAccount ?? !Session.Profile.ParentUsername
    }
  },
  IsAuthenticated: {
    get() {
      return !!Session.Profile.Id
    }
  },
  HasRole: {
    get() {
      return (role: SystemRoles) => {
        return Session.Roles.includes(role)
      }
    }
  },

  HasFeature: {
    get() {
      return (feature: Feature | `${Feature}`) => {
        return Session.Plan.Features.get(feature)?.Active ?? false
      }
    }
  },

  reset: {
    get() {
      return (data?: ServerSessionObject) => {
        merge(Session, getDefaultSessionSettings())
        if (data) {
          Session.set(data)
        }
      }
    }
  },

  set: {
    get() {
      return (newSession: ServerSessionObject) => {
        const {
          UserName,
          Email,
          Plan: {
            Features,
            ...Plan
          },
          ...rest
        } = newSession
        /** Move UserName / Email under Profile */
        rest.Profile.UserName ??= UserName ?? ''
        rest.Profile.Email ??= Email ?? ''

        const mergeFn = (objValue: any, srcValue: any) => {
          /**
           * Enforce that arrays are always arrays,
           * and do not merge values (such as with features).
           * If the incoming value is not an array,
           * set to []
           */
          if (Array.isArray(srcValue) && objValue !== undefined) {
            if (Array.isArray(objValue)) {
              return merge(srcValue, objValue)
            }
            return []
          }
        }
        mergeWith(Session, rest, mergeFn)
        mergeWith(Session.Plan, Plan, mergeFn)

        const featureMap = Session.Plan.Features

        /** Store server feature array as a more convenient Map */
        for (const feature of Features ?? []) {
          featureMap.set(feature.Name, feature)
        }

        /**
         * Allows over-riding of features at the env.js level AND/or
         * the local flag level.
         */
        for (let [key, value] of Object.entries(env)) {
          if (key.startsWith('FEAT')) {
            let storageValue: string | null = null
            try {
              storageValue = localStorage.getItem(key)
            } catch (e) {}

            key = key.replace('FEAT_', '')
            const currentFeature: IFeature = featureMap.get(key) ?? {
              Name: key,
              Active: Boolean(value)
            }
            if (storageValue !== null) {
              currentFeature.Active = storageValue === 'true'
            }
            featureMap.set(key, currentFeature)
          }
        }
      }
    }
  }
})

export { Session }
