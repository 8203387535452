/* global BodyInit */
import env from '/env.js'
import {
  getCurrentScope,
  watch,
  isRef,
  isReactive,
  isProxy,
  toRaw,
  unref,
  ref
} from 'vue'
import { computed, type Computed } from '~/components/utils'
import { RollbarProxy } from '~js/RollbarProxy.ts'
import useSWRV from 'swrv'
import config from './client-config'
import { apiTransformers } from '~/models'
import { resolveClientUrl } from './url'
import { withQuery } from 'ufo'
import type { ICacheItem } from 'swrv/dist/cache'
import type { IsAny } from 'type-fest'
import { useLoadingIndicator } from '#imports'

const { isArray } = Array
/**
 * Inferred from jsv.js
 *
 * This structure is re-used for backwards compatibility,
 * both in the old GL Service Client and in these replacement functions.
 */
export interface StatusError {
  isSuccess: false
  errorCode: any
  message: string
  errorMessage: string
  stackTrace: any
  fieldErrors: any[]
  fieldErrorMap: {}
}
export interface StatusSuccess {
  isSuccess: true
}
export type Status = StatusError | StatusSuccess
/** Done legacy status types */

export const UNKNOWN_ERROR = 'Unknown error - the server may not be returning proper errors'

export function deepToRaw<T extends Record<string, any>>(sourceObj: T): T {
  const objectIterator = (input: any): any => {
    if (isArray(input)) {
      return input.map(item => objectIterator(item))
    } else if (isRef(input)) {
      return objectIterator(unref(input))
    } else if (isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input))
    } else if (input && typeof input === 'object') {
      return Object.keys(input).reduce<T>((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key])
        return acc
      }, {} as T)
    }
    return input
  }

  return objectIterator(sourceObj)
}

type Api = typeof apiTransformers

/** Automatically infer schema based on URL */
type GetSchema<
  S extends string,
  T extends Record<string, any>
> = Api extends Record<S, any>
  ? IsAny<ReturnType<Api[S]>> extends true
    ? T
    : ReturnType<Api[S]>
  : T

type GetOptions<
  T extends Record<string, any>,
  S extends string
> = Parameters<typeof $fetch<T, S>>[1] & {
  revalidate?: boolean
  useCache?: boolean
}

type UrlParts = [
  originalUrl: string,
  urlPath: string,
  fullResolvedUrl: string,
  body?: string
]

class ResponseError extends Error {
  errors: Record<string, string>
  constructor(
    message: string,
    errors?: Record<string, string> | Array<Record<string, string>>
  ) {
    super(message)
    if (isArray(errors)) {
      this.errors = errors.reduce((acc, err) => {
        if ('fieldName' in err && 'message' in err) {
          acc[err.fieldName] = err.message
        }
        return acc
      }, {})
    } else {
      this.errors = errors || {}
    }
  }
}

/**
 * @todo - This should probably have unit tests
 */
const getKey = <Url extends string | null | undefined = string>
  (
    request: Url | (() => Url),
    query: Record<string, any> | null | undefined,
    body: Record<string, any> | BodyInit | null | undefined
  ) => {
  const urlParam = typeof request === 'function' ? request() : request
  if (!urlParam) {
    return null
  }

  let url = urlParam.trim()
  const urlParts = [url, url] as unknown as UrlParts

  /** Add query object to the url */
  if (query) {
    let rawQuery = unref(query)
    /**
     * @note IMPORTANT!!
     * If the query object is null, or one of the query values
     * is explicitly null, this indicates we should not fetch.
     * We do this to prevent unnecessary requests. If you want
     * to omit a parameter, use undefined.
     */
    if (rawQuery === null) {
      return null
    }
    rawQuery = deepToRaw(rawQuery)
    if (Object.values(rawQuery).includes(null)) {
      return null
    }
    for (const [key, value] of Object.entries(rawQuery)) {
      const searchKey = `[${key}]`
      /** Moves values from `query` object like
       * /v3.5/links/[id]
       * {
       *   id: 1
       * } -> /v3.5/links/1 {}
       *
      */
      if (url.includes(searchKey)) {
        url = url.replace(searchKey, value)
        urlParts[1] = url

        delete rawQuery[key]
      }
    }
    urlParts.push(withQuery(resolveClientUrl(url), rawQuery))
  } else {
    urlParts.push(resolveClientUrl(url))
  }

  /** Add body parameters as part of the key */
  if (body) {
    body = typeof body === 'object' ? deepToRaw(unref(body)) : body
    urlParts.push(JSON.stringify(body))
  }
  /** We join these to make a unique key per set of options */
  return urlParts.join(' ')
}

const tryGetErrorMessage = (err: Record<string, any>, key: string) => {
  if (key in err && typeof err[key] === 'string' && err[key]) {
    return new ResponseError(err[key], err?.errors)
  }
}

const processError = (value: unknown) => {
  /**
   * GeniusLink error schema is super unpredictable,
   * but this is the best guess if there's an error or not.
   */
  const processErrorInternal = (err: any) => {
    if (err === true || err === 1) {
      return new ResponseError(UNKNOWN_ERROR)
    }
    if (err && typeof err === 'object') {
      let returnErr: ResponseError | undefined
      if (returnErr = tryGetErrorMessage(err, 'statusMessage')) {
        return returnErr
      } else if (returnErr = tryGetErrorMessage(err, 'message')) {
        return returnErr
      } else if (returnErr = tryGetErrorMessage(err, 'errorMessage')) {
        return returnErr
      }
    }
  }
  if (value && typeof value === 'object') {
    let err = processErrorInternal(value)
    if (!err && 'responseStatus' in value) {
      err = processErrorInternal(value.responseStatus)
    }
    if (!err && 'error' in value) {
      err = processErrorInternal(value.error)
    }
    if (!err && 'isError' in value) {
      err = processErrorInternal(value.isError)
    }
    if (!err && 'hasError' in value) {
      err = processErrorInternal(value.hasError)
    }
    return err
  }
}

/**
 * This is a similar wrapper to what I used on Booklinker,
 * with a few tweaks for a slightly better API.
 */
export const useClient = <
  ResponseType extends Record<string, any> = Record<string, any>,
  Url extends string | null | undefined = string
>(
    request: Url | (() => Url),
    options: GetOptions<ResponseType, Exclude<Url, null | undefined>> = {}
  ) => {
  /** Separate the query as we'll add it to the URL for useSWRV key */
  const { query, revalidate = true, ...opts }: Exclude<typeof options, undefined> = {
    baseURL: env.API_URL,
    credentials: 'include',
    ...options
  }

  const scope = getCurrentScope()
  if (!scope) {
    throw new Error('useClient must be called inside a setup function.')
  }
  return scope.run(() => {
    const loadingIndicator = useLoadingIndicator()
    const internalIsValidating = ref(false)
    type TypedResponse = GetSchema<Exclude<Url, '' | null | undefined>, ResponseType>
    type IResponse = ReturnType<typeof useSWRV<TypedResponse, ResponseError>>
    type Response = Pick<IResponse, 'mutate'> & (
      /**
       * @todo - There might be a way to define this
       * so that templates know that !loading && !error means
       * that data is defined.
       */
      {
        data: Computed<TypedResponse | undefined>
        error: Computed<ResponseError | undefined>
        loading: Computed<boolean>
        validating: Computed<boolean>
      }
    )

    /**
     * Roughly the same pattern we used for Booklinker
     */
    const {
      data: dataRef,
      error: errorRef,
      isValidating,
      mutate
    } = useSWRV<ResponseType>(
      () => {
        internalIsValidating.value = true
        const key = getKey(request, query, opts.body)
        if (key === null) {
          return null
        }
        if (config.cache?.get(key)) {
          internalIsValidating.value = false
        }
        return key
      },
      async urlParts => {
        loadingIndicator.start()
        if (!revalidate) {
          const cacheData = config.cache?.get(urlParts)
          if (cacheData) {
            loadingIndicator.finish()
            return cacheData.data.data
          }
        }
        const parts: UrlParts = urlParts.split(' ')
        /** resolvedUrl will have query parameters if they exist */
        const urlKey = parts[0]
        const resolvedUrl = parts[2]
        const returnData = await $fetch(resolvedUrl, opts)
        loadingIndicator.finish()
        if (urlKey in apiTransformers) {
          return (apiTransformers as any)[urlKey](returnData)
        }
        return returnData
      },
      config
    )

    /** Create observables */
    const data = computed(() => dataRef.value) as Computed<TypedResponse | undefined>
    const error = computed<ResponseError | undefined>(() => {
      if (errorRef.value) {
        return errorRef.value
      }
      return processError(data.value)
    })
    /**
     * @see https://github.com/Kong/swrv?tab=readme-ov-file#useswrvstate
     */
    const loading = computed(() => isValidating.value && data.value === undefined && !error.value)
    const validating = computed(() => data.value && isValidating.value && internalIsValidating.value)

    /**
     * Watch for errors and log
     */
    watch(error, error => {
      if (error && !(error instanceof SyntaxError)) {
        RollbarProxy.error('request error: ' + error.message)
      }
    })
    return {
      data,
      error,
      loading,
      mutate,
      validating
    } as Response
  })!
}

export interface AbortablePromise<T = any> extends Promise<T> {
  abort: () => void
}

/**
 * Promise version of useClient (provides strong typing). This
 * implements a stale-while-revalidate strategy to allow for
 * a fast return of data.
 *
 * Uses a "flatry"-like pattern, where an array of [err, data]
 * is returned unstead of throwing an error with reject().
 */
export const useClientPromise = <
ResponseType extends Record<string, any> = Record<string, any>,
Url extends string = string
>(
    request: Url,
    options: GetOptions<ResponseType, Url> = {}
  ) => {
  type TypedResponse = GetSchema<Url, ResponseType>
  const loadingIndicator = useLoadingIndicator()
  /**
   * @note It looks like query refs aren't properly un-wrapped yet
   */
  const key = getKey(request, options.query, options.body)
  if (!key) {
    const promise = Promise.resolve([new Error('Invalid request'), undefined] as [ResponseError, undefined]) as AbortablePromise<[ResponseError, undefined]>
    promise.abort = () => {}
    return promise
  }
  const parts = key.split(' ') as UrlParts
  const urlKey = parts[0]
  /** resolvedUrl will have query parameters if they exist */
  const resolvedUrl = parts[2]

  options = { credentials: 'include', ...options }

  const controller = new AbortController()

  const abortable = new Promise(resolve => {
    let cacheData: ICacheItem<any> | undefined
    if (options.useCache) {
      cacheData = config.cache?.get(key)
      if (cacheData) {
        resolve([null, cacheData.data.data as TypedResponse])
      }
    }
    loadingIndicator.start()
    /** Always fetch to update the cache */
    $fetch(resolvedUrl, {
      ...options,
      signal: controller.signal
    })
      .then(data => {
        loadingIndicator.finish()
        const err = processError(data)
        if (err) {
          (err as any).isSuccess = false
          throw err
        }
        if (urlKey in apiTransformers) {
          data = (apiTransformers as any)[urlKey](data)
        }
        if (!cacheData) {
          resolve([null, data as TypedResponse])
        } else {
          config.cache?.set(key, data, 0)
        }
      })
      .catch(error => {
        loadingIndicator.finish()
        if (!cacheData) {
          resolve([processError(error)!, undefined])
        }
      })
  }) as AbortablePromise<[ResponseError, undefined] | [null, TypedResponse]>

  abortable.abort = () => controller.abort()
  return abortable
}
