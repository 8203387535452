<script lang="ts">
import { observable } from './components/utils'
import { atom, provide } from 'vue-atoms'
import { useRuntimeConfig } from '#app'

type ColorMode = 'light' | 'dark'

const lastKeyDown = observable(0)
const colorMode = observable<ColorMode>('light')
export const KeyDownAtom = atom(lastKeyDown)
export const ColorModeAtom = atom(colorMode)
</script>

<script setup lang="ts">
import { type Link, useHead } from '@unhead/vue'
import { useEventListener, useStorage } from '@vueuse/core'
import { nextTick, watch, ref, onBeforeMount, onUnmounted, computed } from 'vue'
import { deviceType } from 'detect-it'
import Toast from 'primevue/toast'

const config = useRuntimeConfig()

const debugFields = [
  ['Url', window.location.origin],
  ['Git Branch', config.public.gitBranch],
  ['Git Commit', config.public.gitSha],
  ['Build Date', config.public.buildDate],
  ['Device Type', deviceType],
  ['User Agent', navigator.userAgent]
]

let debugString = ''
let markdownString = '| | |\n|-|-|\n'
for (const [label, value] of debugFields) {
  debugString += `${label}: ${value}\n`
  markdownString += `|**${label}** |${label === 'Url' ? value.replace(/(:)/g, '&#x200B;$1') : value}|\n`
}

const logLabel = `GL Dashboard v${config.public.version}`
console.groupCollapsed(logLabel)
console.info(`
DEBUG
${debugString}

MARKDOWN
${markdownString}
`)
console.groupEnd()

/** We default to touch so that invisible elements for mice are shown */
const currentInputType = ref<'touch' | 'mouse'>('touch')

if (deviceType === 'mouseOnly') {
  currentInputType.value = 'mouse'
}

/** Intercept any legacy <a> links */
useEventListener(document, 'click', (e: PointerEvent) => {
  const target = e.target
  if (target && 'tagName' in target && target.tagName === 'A') {
    const href = (target as HTMLElement).getAttribute('href')

    if (href?.startsWith('/')) {
      e.preventDefault()
      void navigateTo(href)
    }
  }
})

const colorModeStorage = useStorage<ColorMode>('color-mode', 'light')
colorMode.value = colorModeStorage.value

watch(colorMode, mode => {
  colorModeStorage.value = mode
})

provide(KeyDownAtom, lastKeyDown)
provide(ColorModeAtom, colorMode)

useEventListener(document, 'keydown', (e: KeyboardEvent) => {
  const code = e.key?.charCodeAt(0)
  if (code) {
    lastKeyDown(code)
  }
})

/** These are set dynamically for a hybrid device */
if (deviceType === 'hybrid') {
  useEventListener(document, 'touchstart', (e: TouchEvent) => {
    currentInputType.value = 'touch'
  })

  useEventListener(document, 'mouseenter', (e: MouseEvent) => {
    currentInputType.value = 'mouse'
  })
}

const isDark = computed({
  get() {
    return colorMode.value === 'dark'
  },
  set(value: boolean) {
    colorMode.value = value ? 'dark' : 'light'
  }
})

const googleLinks: Link[] = [
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossorigin: ''
  },
  {
    href: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap',
    rel: 'stylesheet'
  }
]

useHead({
  link: () => [
    ...googleLinks,
    {
      rel: isDark.value ? 'stylesheet' : 'alternate stylesheet',
      href: '/css/dark.css'
    },
    {
      rel: isDark.value ? 'alternate stylesheet' : 'stylesheet',
      href: '/css/light.css'
    }
  ],
  htmlAttrs: () => ({
    class: `uses-${currentInputType.value}`
  })
})

const clickHandler = function(event: MouseEvent) {
  const target = event.target
  const a = target instanceof HTMLElement && target.closest('a')

  if (a) {
    const href = a.getAttribute('href')
    if (href && href.startsWith('/')) {
      event.preventDefault()
      void navigateTo(href)
    }
  }
}
onBeforeMount(() => {
  document.addEventListener('click', clickHandler)
})
onUnmounted(() => {
  document.removeEventListener('click', clickHandler)
})
</script>
<template>
  <Toast position="top-center" />
  <slot />
</template>

<style lang="less">
@layer legacy, reset, primevue;
@import "~/assets/less/style.less";
</style>
