/* eslint-disable no-restricted-imports */
/** @todo - Replace dayjs with Luxon */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { DateTime } from 'luxon'

dayjs.extend(utc)

/**
 * @note As noted in the Dashboard's README.md, dates rendered for display are rendered as if the user's
 * local timezone is UTC. By default, libraries like Luxon will render dates in the user's local timezone
 * after parsing a UTC string.
 */
export function formatUtcDate(
  utcDate: string | DateTime,
  options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }
) {
  const date = utcDate instanceof DateTime ? utcDate : DateTime.fromISO(utcDate).toUTC()
  return date.setLocale('en-US').toLocaleString(options)
}

export function formatUtcDateRange(
  utcStart: string | DateTime,
  utcEnd: string | DateTime,
  options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }
) {
  const startDate = utcStart instanceof DateTime ? utcStart : DateTime.fromISO(utcStart).toUTC()
  const endDate = utcEnd instanceof DateTime ? utcEnd : DateTime.fromISO(utcEnd).toUTC()
  /** Remove year, we'll render manually */
  const { year, ...opts } = options
  if (startDate.month === endDate.month && startDate.year === endDate.year) {
    return `${startDate.toLocaleString(opts)} - ${endDate.day}, ${endDate.year}`
  }
  return `${startDate.toLocaleString(opts)} - ${endDate.toLocaleString(opts)}, ${endDate.year}`
}

export { dayjs }
