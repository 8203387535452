import revive_payload_client_Ynt16dsU0E from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EZ6RubKATi from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ILqNOAeYfF from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pxgCZTwWGt from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.24.4_vite@5.4.14_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yrAU4oi6Lc from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cc8ZBx7Lbv from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1LoluGjBiK from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ywtRYubZ2u from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/core.git/sln/dashboard/dashboard-fe/.nuxt/components.plugin.mjs";
import prefetch_client_DaABxRmkAd from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt@3.14.1592_@types+node@18.19.74_eslint@9.19.0_less@4.2.2_rollup@4.24.4_sass@1.83.4_typesc_wdum5ajsw6mvdyvgn3vzwfkfae/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_1RV7Bn5Y28 from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_@unhead+vue@1.11.18_h3@1.14.0_rollup@4.24.4_unhead@1.11.18_vite@5.4.14_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_XYTeUTU2VF from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.4_vite@5.4.14_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_pOUuWXuTZe from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.24.4_vite@5.4.14_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import defaults_y2Wr9X019z from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt-schema-org@3.5.0_@unhead+vue@1.11.18_rollup@4.24.4_unhead@1.11.18_vite@5.4.14_vue@3.5.13/node_modules/nuxt-schema-org/dist/runtime/app/plugins/defaults.js";
import init_al6xg5kiU0 from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/nuxt-schema-org@3.5.0_@unhead+vue@1.11.18_rollup@4.24.4_unhead@1.11.18_vite@5.4.14_vue@3.5.13/node_modules/nuxt-schema-org/dist/runtime/app/plugins/init.js";
import css_register_client_vpMtYJS9P9 from "/core.git/sln/dashboard/dashboard-fe/src/plugins/css-register.client.ts";
import vue_plugins_client_xJEQ5FQluE from "/core.git/sln/dashboard/dashboard-fe/src/plugins/vue-plugins.client.ts";
import defaults_CbG69KEZ9J from "/core.git/sln/dashboard/dashboard-fe/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_@unhead+vue@1.11.18_h3@1.14.0_rollup@4.24.4_unhead@1.11.18_vite@5.4.14_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_Ynt16dsU0E,
  unhead_EZ6RubKATi,
  router_ILqNOAeYfF,
  _0_siteConfig_pxgCZTwWGt,
  payload_client_yrAU4oi6Lc,
  navigation_repaint_client_cc8ZBx7Lbv,
  check_outdated_build_client_1LoluGjBiK,
  chunk_reload_client_ywtRYubZ2u,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DaABxRmkAd,
  titles_1RV7Bn5Y28,
  siteConfig_XYTeUTU2VF,
  inferSeoMetaPlugin_pOUuWXuTZe,
  defaults_y2Wr9X019z,
  init_al6xg5kiU0,
  css_register_client_vpMtYJS9P9,
  vue_plugins_client_xJEQ5FQluE,
  defaults_CbG69KEZ9J
]