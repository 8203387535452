import { type App } from 'vue'
import { createHead } from '@unhead/vue'
import PrimeVue from 'primevue/config'
// import Tooltip from 'primevue/tooltip'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import ref from 'vue-ref'
import VWave from 'v-wave'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
import {
  TippyDirective,
  Tippy,
  TippySingleton
} from 'tippy.vue'
import tippy, { roundArrow, type DefaultProps } from 'tippy.js'
import { setup } from './scripts/lib/intercom/IntercomProxy'

/** Setup is abstracted here so that Storybook can add all required plugins as well */
export const setupVueApp = (app: App) => {
  app.config.errorHandler = (err, vm, info) => {
    console.error(err)
  }

  const tippyProps: Partial<DefaultProps> = {
    arrow: roundArrow,
    placement: 'bottom',
    theme: 'genius',
    animation: 'shift-away',
    allowHTML: true,
    interactive: true,
    appendTo: document.body
  }

  app.use(ref)
  app.use(createHead())
  app.use(PrimeVue)
  tippy.setDefaultProps(tippyProps)
  app.directive('tooltip', TippyDirective)
  app.component('Tippy', Tippy)
  app.component('TippySingleton', TippySingleton)
  app.use(VWave, {
    initialOpacity: 0.15,
    finalOpacity: 0.05,
    duration: 0.3,
    cancellationPeriod: 30
  })
  app.use(DialogService)
  app.use(ToastService)
  app.use(ConfirmationService)

  /** Set up Intercom */
  setup()
}
