import { abbreviateNumber } from 'js-abbreviation-number'

/**
 * Replaced hand-rolled confusing function with a userland solution that has test coverage
 *
 * @param {number} number
 * @param {number} decPlaces
 */
export function abbrNum(number = 0, decPlaces = 0) {
  const abbr = abbreviateNumber(number, decPlaces, {
    symbols: ['', 'k', 'M', 'B', 'T', 'P', 'E']
  })
  return abbr
}

export function numberWithCommas(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (parts.length === 1) { // don't add "." if no digits
    return parts[0]
  }
  return parts.join('.')
}
