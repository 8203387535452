import {
  type GroupOptionData,
  type GroupOption,
  type CustomDomainData,
  type GetLinksListResponse,
  type GetLinkResponse
} from './links-v4'
import {
  type ProductDetailsResponse,
  type LinksListRequest
} from './links-v1'
import {
  type Overrides,
  type Override,
  type LanguageOptions,
  type OverrideRules,
  type OverrideOptions
} from './overrides'
import type {
  ReportDataResponse,
  ClickDataByResolutionResponse,
  MapDataResponse
} from './reports'
import type {
  ProductsResponse
} from './products'
import type {
  CountriesResponse
} from './countries'
import type {
  PixelResponse
} from './pixels'
import type {
  PaymentsResponse,
  InvoicesResponse,
  CurrentPeriodResponse,
  CreditResponse,
  RefundResponse
} from './billing'

export const apiTransformers = {
  '/v1/custom-domains/domains'(data: {
    domains: CustomDomainData[]
  }) {
    return data.domains
  },
  '/v1/groups/list'(data: {
    groups: GroupOptionData[]
  }): GroupOption[] {
    return data.groups
      .map(({
        id,
        name,
        shortName,
        enabled
      }) => ({
        id,
        name,
        shortName,
        /**
         * On the FE, we use the name as the value to dynamically
         * match new groups to the dropdown list (when creating /
         * editing links).
         */
        value: name,
        disabled: Boolean(!enabled)
      }))
      .sort(function(a, b) {
        // Only attempt to sort if the group names are valid
        if (a.name && b.name) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        }
        return -1
      })
  },
  '/api/v1/reports/shorturl/check-vanity-availability'(data: {
    isError: boolean
  }) {
    return data
  },
  '/api/v1/link/override/settings'(data: Overrides) {
    const sortOverrideOptions = (a: Override, b: Override) => {
      const textA = a.displayName.toLowerCase()
      const textB = b.displayName.toLowerCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    }
    return {
      browserOptions: data.browserOverrideOptions.sort(sortOverrideOptions),
      deviceOptions: data.deviceList.sort(sortOverrideOptions),
      osOptions: data.osList.sort(sortOverrideOptions),
      systemPresetOptions: data.systemPresetsList.sort(sortOverrideOptions),
      userPresetOptions: data.userPresetsList.sort(sortOverrideOptions)
    } satisfies OverrideOptions
  },
  /** @todo - This is a massive 0.5MB payload currently */
  '/api/v1/genius/languageOptions'(data: LanguageOptions) {
    /**
     * Quick way to filter duplicates,
     * as Maps require unique keys.
     */
    return [...new Map(
      data.languages
        .sort((a, b) => a.englishName.localeCompare(b.englishName))
        .map(item => [item.englishName, item])
    ).values()]
  },
  '/api/v1/links/retrieveoverride'(data: OverrideRules) {
    return data.holder
  },
  '/v1/links/list'(data: LinksListRequest) {
    return data.results
  },
  '/api/v1/shorturl/preview-product-details'(data: ProductDetailsResponse) {
    return data.link
  },
  '/v1/reports/minireport-topattributes'(data: ReportDataResponse) {
    return data.attributeNameToClicksByTab[0].value
  },
  '/v1/reports/clicks-by-country'(data: MapDataResponse) {
    return data.iso2WithClicks
  },
  '/v1/reports/link-clicks-by-country'(data: MapDataResponse) {
    return data.iso2WithClicks
  },
  '/api/v1/reports/group-clicks-by-country'(data: MapDataResponse) {
    return data.iso2WithClicks
  },
  '/v1/reports/click-trend-by-resolution'(data: ClickDataByResolutionResponse) {
    return data
  },
  '/v1/reports/link-click-trend-by-resolution'(data: ClickDataByResolutionResponse) {
    return data
  },
  '/v1/reports/group-click-trend-by-resolution'(data: ClickDataByResolutionResponse) {
    return data
  },
  '/v3/product-suggestions'(data: ProductsResponse) {
    return data.products
  },
  '/api/v1/genius/countryOptions'(data: CountriesResponse) {
    return data.countries.sort((a, b) => a.name.localeCompare(b.name))
  },
  '/v1/user-trackingpixels'(data: PixelResponse) {
    return data.trackingPixels
  },
  '/v3.5/links'(data: GetLinksListResponse) {
    return data
  },
  '/v3.5/links/[id]'(data: GetLinkResponse) {
    return data.data
  },
  '/v1/payments'(data: PaymentsResponse) {
    return data.payments
  },
  '/v1/invoices'(data: InvoicesResponse) {
    return data.invoices
  },
  '/v1/invoices/current-period'(data: CurrentPeriodResponse) {
    return data.invoice
  },
  '/api/v1/billing/credit'(data: CreditResponse) {
    return data.credits
  },
  '/api/v1/billing/refund'(data: RefundResponse) {
    return data
  },
  '/api/v1/team/subaccount-details'(data: Array<{ email: string, firstName: string, lastName: string, username: string, isSetupComplete: boolean }>) {
    return data
  }
} as const
